<template>
  <router-view></router-view>
</template>

<script>
export default {
  created() {
    document.body.onselectstart = document.body.oncontextmenu = function(){
      return false;
    } 
  },
  destroyed() {
    document.body.onselectstart = document.body.oncontextmenu = function(){
      return true;
    } 
  }
};
</script>

<style>
</style>